a[href^="tel:"] {
white-space: nowrap;
}
h1, h2, h3, h4, h5, h6 {
  margin: .5818181818em 0 .5818181818em;
  font-weight: @bold;
  line-height: 1.15;
  color: @colorHeader;
  font-family: @fontHeader;

  &:first-child {
    margin-top: 0;
  }

  strong {
    color: @colorPrimary;
    font-weight: inherit;
  }
}

h1 {
  .font-size(h1);
}
h2 {
  .font-size(h2);
}
.h1 {
  .font-size(h1);
}
h3 {
  .font-size(h3);
}
h4 {
  .font-size(h4);
}
h5 {
  .font-size(h5);
}
h6 {
  .font-size(h6);
}

.hide-mobile {
  @media @phone {
    display: none;
  }
}
.hide-desktop {
  @media @phoneUp {
    display: none;
  }
}

.image-desktop {
  @media @phone {
    display: none;
  }
}
.image-mobile {
  @media @phoneUp {
    display: none;
  }
}

.categories-wrapper,
.section-inner {
  .main-header + & {
    margin-top: 186/18rem;

    @media @phone {
      margin-top: 5rem;
    }
  }
}
.main-header + .bg-dark--services {
  margin-top: 186/18rem;

  @media @phone {
    margin-top: 5rem;
  }
}

.categories-wrapper {
  .main-header + & {
    @media @phone {
      margin-top: 4rem;
    }
  }
}
.categories-wrapper {
  @media @phoneUp {
    border-bottom: 1px solid #3C4041;
  }

  .section-inner {
    margin-bottom: 0;
    margin-top: 0;
  }

  & + .section-inner {
    margin-top: 64/18rem;
  }
}

.section-inner {
  width: 100%;
  max-width: 1238/16rem;
  margin:0 auto;
  padding: 0 1rem;

  &--my {
    margin-top:  var(--top-gap);
    margin-bottom:  var(--top-gap);
  }

  &--mb {
    margin-bottom:  var(--top-gap);
  }

  &--page {
    margin-bottom: 0 !important;
  }

  &--narrow {
    max-width: 1092/18rem;
  }

  @media @phone {
    padding-left:1.5rem;
    padding-right:1.5rem;
  }
}

.section {
  width: 100%;
  margin: var(--top-gap) auto;

  &.bg-dark,
  &.bg-dark-half,
  &.bg-light-half,
  &.bg-light {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  &--mb-0 {
    margin-bottom: 0;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.bg-dark {
  background: @colorSecondary;

  &.section {
    margin-top: 0;
    margin-bottom: 0;
  }

  &--services {
    position: relative;
    background: unset;

    .section-inner--my {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      left:0;
      bottom: 0;
      top:34em;
      width: 100%;
      background: @colorSecondary;
      z-index: -1;
    }
  }

  &--50-top {
    background: unset;
    position: relative;
    padding-top: 1px;
    padding-bottom: 1px;

    &::before {
      content: "";
      position: absolute;
      left:0;
      top: 0;
      bottom: 50%;
      width: 100%;
      background: @colorSecondary;
      z-index: -1;
    }
  }
}
.bg-light {
  background: #F4F4F5;

  &.section {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.bg-light-half {
  position: relative;

  &.section {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: unset;
  }

  &::before {
    content: "";
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    max-height: 530/16rem;
    height: 100%;
    background: #F4F4F5;
    z-index: -1;

    @media @phone {
      max-height: 400/16rem;
    }
  }

  &--bottom {

    &::before {
      top:50%;
      bottom: 0;
      height: auto;
      max-height: unset;
    }
    &.section {
      padding-bottom: 0;
      padding-top: unset;
    }
  }

  .section-inner {
    position: relative;
  }
}

.section-title {
  text-align: center;
  margin-bottom: 2rem;
  position: relative;

  &__label {
    font-family: @font;
    letter-spacing: .3em;
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: @regular;
    margin-top: 0;
    margin-bottom: .5em;
  }

  h2 {
    //max-width: 6601820rem;
    margin:0 auto;

    &:not(:last-child) {
      margin-bottom: 24/16rem;
    }
  }

  h4 {
    font-size: 26/16rem;
    font-family: @font;
    font-weight: @regular;
    color: @colorText;

    @media @phone {
      .font-size(p1);
    }
  }
  p {
    max-width: 780/20em;
    margin-right: auto;
    margin-left: auto;

    @media @phoneUp {
      .font-size(p1);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--white {
    color: #fff;

    h2, .section-title__label {
      color: inherit;
    }
  }

  &--left {
    text-align: left;

    p {
      margin-left: 0;
    }

    h2 {
      margin-left: 0;
    }
  }

  @media @phoneUp {
    margin-bottom: 4rem;

    &__label {
      font-size: 1rem;
      margin-bottom: 24/16em;
    }

    &--left-mobileup {
      text-align: left;

      p {
        margin-left: 0;
      }

      h2 {
        margin-left: 0;
      }
    }
  }
}


.btn-ico {
  display: block;
  overflow: hidden;
  width: 28/16rem;
  height: 28/16rem;
  position: relative;
  text-decoration: none;
  isolation: isolate;

  &--24 {
    width: 24/16rem;
    height: 24/16rem;
  }

  a:hover &,
  button:hover & {
    svg {
      transform: translateY(-105%);
    }
  }

  svg {
    position: relative;
    z-index: 1;
    fill:#fff;
    stroke: #fff;
    width: 100%;
    height: 100%;
    transition: transform .3s ease;

    & + svg {
      fill: #fff;
      stroke: #fff;
      position: absolute;
      top:105%;
      left:0;
    }
  }
}

.btn {
  --bg:@colorPrimary;
  --border:var(--bg);
  --bgHover:@colorSecondary;
  --borderHover:var(--border);
  --color:#fff;
  --colorHover:var(--color);

  display: inline-flex;
  background: var(--bg);
  color: var(--color);
  font-family: @fontHeader;
  font-weight: @regular;
  font-size: 18/16rem;
  line-height: 20/18;
  gap: 8/18em;
  padding: 16/18em 28/18em;
  border-radius: 3em;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border);
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  position: relative;

  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  &::before {
    content: "";
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: var(--bgHover);
    border-color: var(--borderHover);
    border-radius: 50%;

    opacity: 0;
    transform:rotateY(70deg) scale(.8);
    transform-origin: 95% 50%;
    transition: .15s ease-out;
  }

  span {
    position: relative;
  }

  svg {
    width: 15/18em;
    height: 15/18em;

    fill: currentColor;
    stroke: currentColor;
    transition: fill .3s ease;
    position: relative;
  }

  &--sm {
    font-size: 1rem;
    line-height: 18/16;
    padding: 12/16em 22/16em;

    @media @phone {
      font-size: 14px;
      font-weight: @bold;
    }
  }

  &:hover {
    color:var(--colorHover);
    border-color: var(--borderHover);

    &::before {
      opacity: 1;
      border-radius: 3rem;
      transform: rotateY(0deg) scale(1);
    }
  }

  &--secondary {
    --color:#fff;
    --bg:#42434e;
    --bgHover:@colorPrimary;
  }

  &--ghost {
    --color:@colorPrimary;
    --colorHover:#fff;
    --bg:transparent;
    --bgHover:@colorPrimary;
    --border: @colorPrimary;
  }

  &--white {
    --color:@colorPrimary;
    --colorHover:#fff;
    --bg:#fff;
    --bgHover:@colorSecondary;
  }

  &--mw {
    min-width: 10em;
  }
}

.btn-link {
  color:inherit;
  display: inline-flex;
  align-items: center;
  font-size: 18/16rem;
  gap: 8/18em;
  text-decoration: none;
  cursor: pointer;

  svg {
    width: 15/16em;
    height: 15/16em;

    fill: currentColor;
    stroke: currentColor;
    transition: fill .3s ease;
    position: relative;
  }

  &:hover {
    color: @colorPrimary;
  }

  &--c {
    color: @colorPrimary;

    &:hover {
      color: @colorSecondary;
    }
  }
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 64/18rem;
  justify-content: center;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  margin:64/16rem 0;

  &__arrow {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    height: 3em;
    border-radius: 4px;
    position: relative;
    background: #F4F4F5;
    color:@colorText;
    text-decoration: none;
    overflow: hidden;
    isolation: isolate;

    &--prev {
      svg {
        transform: scale(-1, -1);
      }
    }

    svg {
      width: 20/16rem;
      height: 20/16rem;
      fill: currentColor;
      stroke: currentColor;
    }

    &:hover {
      color:#fff;
      background: @colorSecondary;
    }

    &--inactive {
      opacity: 0;
      cursor: default;
      pointer-events: none;
    }
  }

  &__pages {
    list-style: none;
    margin:0 10px;
    padding:0;

    display: flex;
    align-items: center;
    gap:.5rem;


    span {
      padding: 0 .75em;
      min-width: 2.25em;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      background: @colorPrimary;
      color: #fff;
      border-radius: 4px;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      background: #F4F4F5;
      color:@colorText;
      padding: 0 .75em;
      min-width: 2.25em;
      height: 3em;
      border-radius: 4px;

      &:hover {
        background: @colorSecondary;
        color: #fff;
      }

      &.current {
        background: @colorPrimary;
        color: #fff;
      }
    }
  }

  @media @phone {
    font-size: .8125em;
    margin-top: 40/16rem;
  }
}

.user {
  display: flex;
  align-items: center;
  gap: 1rem;

  &__avatar {
    width: 64/16rem;
    height: 64/16rem;
    border-radius: 10em;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10em;
    }
  }

  &__name {
    strong {
      font-weight: @regular;
      font-size: 18/16rem;
    }

    span {
      display: block;
      text-transform: uppercase;
      font-size: 12/16rem;
      letter-spacing: .2em;
    }
  }

  @media @phoneUp {
    //flex-direction: column;;
    //text-align: center;

    &__avatar {
      width: 90/16rem;
      height: 90/16rem;
    }
  }
}

.breadcrumbs {
  display: flex;
  padding: 0;
  margin: 0;
  gap: .75em;
  color: @colorText2;

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;

  @media @phone {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: .35em;
  }

  li {
    display: flex;
    align-items: center;
    gap: .75em;

    &:not(:first-child)::before {
      content: "";
      width: .6em;
      height: 1em;
      background: url(../img/breadcrumbs.svg) no-repeat 50% 50% / contain;
    }
  }

  a {
    color: inherit;
    display: block;
    text-decoration: none;

    &:hover {
      color:@colorSecondary;
    }
  }

  & + h1, & + h2, & + h3 {
    margin-top: .4em;
  }
}


.modal {
  position: fixed;
  inset:0;

  display: none;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  background: rgba(255,255,255,.8);
  backdrop-filter: blur(1px);
  overflow: auto;
  padding: 2em;
  z-index: 109;

  @media @phone {
    padding: 1em;
  }

  &__close {
    position: absolute;
    padding: 8/16rem;
    color:#fff;
    border: 1px solid #fff;
    right:1rem;
    top:1rem;
    width: 38/16rem;
    height: 38/16rem;
    background: unset;
    border-radius: 3em;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:hover {
      background: #fff;
      color: @colorSecondary;
      //border-color: @colorPrimary;
    }

  }

  &__inner {
    background: @colorSecondary;
    color:#fff;
    border-radius: 20px;
    max-width: 49.625rem;
    padding: 3rem 2rem;
    width: 100%;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;

    h1, h2, h3, h4 {
      color: inherit;
    }
  }

  &__sizer {
    max-width: 560/16rem;
    margin: 0 auto;
  }


  body.is-popup &.is-active {
    display: flex;
    animation: fade-in 1 .3s;

  }

  &.is-active {
    .modal__inner {
      animation: open-popup .3s .3s 1 backwards;
    }
  }

  .js-form {
    margin-top: 2em;
  }
}

.nb-counter {
  position: relative;

  b {font-weight: inherit;}

  .number-animation {
    position: absolute;
    left:0;
    white-space: nowrap;
  }
}

.doc-grid {
  width: 100%;
  display: flex;
  color:#fff;

  &__wrapper {
    width: 90%;
    margin:0 auto;
    max-width: 1300px;


    .tag {
      text-transform: unset;
      border-radius: unset;
      padding: unset;
      text-decoration: none;
      z-index: unset;
      background: unset;
      color: inherit;
    }


    & > h3 {
      padding: 1rem;
    }

    & > p {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  &__col {
    width: 50%;
    padding: 1rem;
  }
}

.doc-form {
  background: @colorSecondary;
  padding: 1em;
  margin-top: .5em;

  h4 {
    color: inherit;
  }
}