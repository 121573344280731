@hamburger-padding: 4px 0 4px;
@hamburger-width: 38px;
@hamburger-height: 4px;
@hamburger-spacing: 6px;
@hamburger-border-radius: 2px;

.hamburger {
  --hamburger-color: #fff;

  .main-header--light &,
  .is-menu &,
  .is-sticky &{
    --hamburger-color: @colorSecondary;
  }

  padding: 0;
  width: 42px;
  height: 42px;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  outline: 0;
  font: inherit;
  color: inherit;
  text-transform: none;
  border: 0;
  overflow: visible;

  background: transparent;
  text-align: center;
  //position: absolute;
  z-index: 5;
  .transition();
  display: none;
  margin: 0;

  @media @menu {
    display: flex;
  }

  &__box {
    height: @hamburger-height * 3 + @hamburger-spacing * 2;
    width: @hamburger-width;
    position: relative;
  }

  &__inner {
    display: block;
    top: 50%;
    margin-top: @hamburger-height / -2;
    left: 0;

    &,
    &:before,
    &:after {
      width: @hamburger-width;
      height: @hamburger-height;
      background-color: var(--hamburger-color);
      border-radius: @hamburger-border-radius;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &:before,
    &:after {
      content: "";
      display: block;
    }

    &:before {
      top: (@hamburger-spacing + @hamburger-height) * -1;
    }

    &:after {
      bottom: (@hamburger-spacing + @hamburger-height) * -1;
    }
  }

  &__inner {
    top: @hamburger-height / 2;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

    &:before {
      top: @hamburger-height + @hamburger-spacing;
      transition: opacity 0.15s 0.4s ease;
    }

    &:after {
      top: (@hamburger-height * 2) + (@hamburger-spacing * 2);
      transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }


    .is-menu & {
      @y-offset: @hamburger-spacing + @hamburger-height;

      transform: translate3d(0, @y-offset, 0) rotate(135deg);
      transition-delay: 0.1s;

      &::before {
        transition-delay: 0s;
        opacity: 0;
      }

      &::after {
        transform: translate3d(0, @y-offset * -2, 0) rotate(-270deg);
        transition-delay: 0.1s;
      }
    }
  }
}