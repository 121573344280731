:root {
    --wp--style--gallery-gap-default: 2.4rem;

    @media @phone {
        --wp--style--gallery-gap-default: 2rem;
    }
}
.content-style {
    //--fs-h2: 2.4rem;
    //
    //@media @phone {
    //    --fs-h2: 1.44rem;
    //    --fs-h3: 1.22rem;
    //    --fs-h4: 1.125rem;
    //}

    @media @phoneUp {
        &.services-page__content {
            .font-size(p);
        }

        &.faq__item__content {
            hr {
                margin: 1.75em 0;
            }
        }
    }

    h1, h2, h3, h4, h5 {
        margin: .8em 0 1.25em;

        &:first-child {
            margin-top: 0;
        }
    }

    h2 {
        .font-size(h4)
    }
    h3 {
        .font-size(h5)
    }
    h4 {
        .font-size(h6)
    }

    p {
        margin: 2em 0;
    }
    a:not([class]) {
        color:inherit;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .wp-block-image {
        margin:2.5em auto;
    }

    .wp-caption {
        width:100% !important;
        margin:1em auto;
        font-size:.7em;
    }

    .wp-caption-text {
        margin-top:.5em;
    }

    .alignleft:not(.is-layout-flex)  {
        float:left;
        margin:.9em 25px 1em 0px;
        max-width: 50%;

        @media @phone {
            float:none;
            margin:1em auto;
            max-width: 100%;
        }
    }


    .alignright:not(.is-layout-flex)  {
        float:right;
        margin:.9em 0 1em 25px;
        max-width: 50%;

        @media @phone {
            float:none;
            margin:1em auto;
            max-width: 100%;
        }
    }

    .aligncenter:not(.is-layout-flex) {
        display: block;
        float:none;
        text-align: center;
        margin:2em auto;
    }


    blockquote {
        margin:2em 0;
        //margin-right: 2em;
        position: relative;
        font-size:20/16em;
        padding: 3em 2em;
        border-radius: 10/16rem;
        background: @colorPrimary url(../img/bg-quotation.png) no-repeat 100% 100% / cover;
        color:#fff;

        @media @phone {
            font-size: 1em;
            margin: 2em -1rem;
            padding: 1.5em 1rem;
        }

        p:first-child {
            font-weight: inherit;
            color:inherit;
        }
        p {
            max-width: none;
            margin-left: 0;
            margin-right: 0;

            &:first-child {
                margin-top:0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    ul.wp-block-list {
        list-style: none;
        margin-bottom: 2em;
        margin-top: 2em;
        padding-left: 0;

        & > li {
            position: relative;
            padding: 0 0 0 1.5em;
            margin: 1em 0;

            &::before {
                content: "";
                position: absolute;
                left:3px;
                top:12/18em;
                width: 6/18em;
                height: 6/18em;
                border-radius: 50%;
                box-shadow: 0 0 0 3px @colorPrimary;
                transform: translate(0, -50%);
            }

            ul {
                margin-left: 0;
                margin-top: 1em;
            }
        }
    }

    ol {
        padding-left: 0;
        list-style-type: none;
        counter-reset: ordered-list;

        & > li {
            counter-increment: ordered-list;
            margin: .8em 0;
            position: relative;
            padding: 0 0 0 2.5em;

            &::before {
                content: counter(ordered-list);
                color: @colorPrimary;
                font-weight: @semiBold;
                min-width: 1.8em;
                font-size: .9em;
                height: 1.8em;
                text-align: center;
                line-height: 1.6;
                position: absolute;
                left:0;
                top:-2px;
                border: 2px solid @colorPrimary;
                border-radius: 2em;
            }
        }

        ol {
            list-style: lower-latin;
            padding-left: 1.25em;

            & > li {
                padding-left: 0;
                &::before {
                    display: none;
                    content: "";
                }
            }
        }

        ul {
            list-style: circle;
            padding-left: 1.25em;

            li {
                margin: .5em 0;
            }
        }
    }

    ul.wp-block-gallery,
    ul.blocks-gallery-grid {
        margin-bottom: 0;
    }

    ul.wp-block-gallery + p[style="text-align:center"],
    ul.blocks-gallery-grid + p[style="text-align:center"]{
        margin-top: 0;
    }

    img {
        width: auto;
        max-width: 100%;
        height: auto;
    }

    ul.wp-block-gallery,
    ul.blocks-gallery-grid {
        list-style: none;
        margin-left:-5px;
        margin-right:-5px;

        & > li {
            padding: 5px;
            margin: 0;

            &::before {
                display: none;
            }
        }
    }

  ul.wp-block-gallery {
    .blocks-gallery-caption {
      font-size: .9em;
    }
  }
  .blocks-gallery-grid.columns-3 .blocks-gallery-image, .blocks-gallery-grid.columns-3 .blocks-gallery-item, .wp-block-gallery.columns-3 .blocks-gallery-image, .wp-block-gallery.columns-3 .blocks-gallery-item,
  .blocks-gallery-grid.columns-4 .blocks-gallery-image, .blocks-gallery-grid.columns-4 .blocks-gallery-item, .wp-block-gallery.columns-4 .blocks-gallery-image, .wp-block-gallery.columns-4 .blocks-gallery-item,
  .blocks-gallery-grid.columns-5 .blocks-gallery-image, .blocks-gallery-grid.columns-5 .blocks-gallery-item, .wp-block-gallery.columns-5 .blocks-gallery-image, .wp-block-gallery.columns-5 .blocks-gallery-item {
    margin-right: 0;
  }

    hr {
        border: 0;
        border-top: 1px solid #f4f4f5;
        margin: 3em 0;
    }

    .gallery {
        margin: 0 -5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .gallery-item {
            padding: 0 5px;
        }
    }


    mark {
        padding: 1px 3px;
        border-radius: 2px;
        color: inherit;
    }

    .wp-block-table {
        margin: 2.5em 0;
        width: 100%;

        @media @phone {
            margin: 2em -1.5rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            width: calc(100% + 3rem);
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            display: block;
            padding-bottom: .35em;

            background:
                linear-gradient(to right, white 30%, rgba(255,255,255,0)),
                linear-gradient(to right, rgba(255,255,255,0), white 70%) 0 100%,
                radial-gradient(farthest-side at 0% 50%, rgba(0,0,0,0.2), rgba(0,0,0,0)),
                radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,0.2), rgba(0,0,0,0)) 0 100%;
            background-repeat: no-repeat;
            background-size: 4em 100%, 4em 100%, 1.4em 100%, 1.4em 100%;
            background-position: 0 0, 100%, 0 0, 100%;
            background-attachment: local, local, scroll, scroll;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            .font-size(p3);
            
            &.has-fixed-layout {
                table-layout: fixed;
            }

            th, td {
                padding: 1em 1.2em;
                border: 1px solid @colorGray;
                text-align: left;
                vertical-align: top;
                line-height: 1.4;
            }

            th {
                background: @colorLight;
                font-weight: @semiBold;
                color: @colorHeader;
            }

            thead {
                tr {
                    border-bottom: 2px solid @colorGray;
                }
            }

            tbody {
                tr {
                    &:nth-child(even) {
                        background: rgba(243, 160, 131, 0.03);
                    }
                    
                    &:hover {
                        background: rgba(243, 160, 131, 0.06);
                    }
                }

                td {
                    strong {
                        color: @colorHeader;
                    }
                }
            }

            @media @phone {
                min-width: 600px; // Minimalna szerokość dla scrollowania
                
                td, th {
                    min-width: 120px; // Minimalna szerokość komórek
                    
                    &:first-child {
                        min-width: 140px; // Szersza pierwsza kolumna
                    }
                }
            }
        }

        figcaption {
            margin-top: .75em;
            font-size: .875em;
            color: @colorText2;
            text-align: center;
        }
    }

}

.wp-block-embed {
    margin: 2.5em 0;

    iframe {
        width: 100%;
    }
}

/**
     * 5.5 - Embed
     */
.wp-block-embed {
    &.alignleft,
    &.alignright {
        max-width: 360px;
        width: 100%;
    }

    figcaption {
        margin-top: 0.5em;
        margin-bottom: 1em;
        color: #555d66;
        text-align: center;
        font-size: 13px;
    }



    &.wp-embed-aspect-21-9 .wp-block-embed__wrapper,
    &.wp-embed-aspect-18-9 .wp-block-embed__wrapper,
    &.wp-embed-aspect-16-9 .wp-block-embed__wrapper,
    &.wp-embed-aspect-4-3 .wp-block-embed__wrapper,
    &.wp-embed-aspect-1-1 .wp-block-embed__wrapper,
    &.wp-embed-aspect-9-16 .wp-block-embed__wrapper,
    &.wp-embed-aspect-1-2 .wp-block-embed__wrapper {
        position: relative;
    }

    &.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before,
    &.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before,
    &.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before,
    &.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before,
    &.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before,
    &.wp-embed-aspect-9-16 .wp-block-embed__wrapper::before,
    &.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
        content: "";
        display: block;
        padding-top: 50%;
    }

    &.wp-embed-aspect-21-9 .wp-block-embed__wrapper iframe,
    &.wp-embed-aspect-18-9 .wp-block-embed__wrapper iframe,
    &.wp-embed-aspect-16-9 .wp-block-embed__wrapper iframe,
    &.wp-embed-aspect-4-3 .wp-block-embed__wrapper iframe,
    &.wp-embed-aspect-1-1 .wp-block-embed__wrapper iframe,
    &.wp-embed-aspect-9-16 .wp-block-embed__wrapper iframe,
    &.wp-embed-aspect-1-2 .wp-block-embed__wrapper iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before {
        padding-top: 42.85%;
    }

    &.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before {
        padding-top: 50%;
    }

    &.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before {
        padding-top: 56.25%;
    }

    &.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before {
        padding-top: 75%;
    }

    &.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before {
        padding-top: 100%;
    }

    &.wp-embed-aspect-9-6 .wp-block-embed__wrapper::before {
        padding-top: 66.66%;
    }

    &.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
        padding-top: 200%;
    }
}


//.wp-block-image {
//    border-radius: 8px;
//    overflow: hidden;
//    background: #141618;
//
//    img {
//        html.js & {
//            opacity: 0;
//            transform: scale(1.1);
//        }
//    }
//}



.single {
    &__title {
        max-width: 800/16rem;
        margin-bottom: 50/16rem;

        .date {
            display: block;
            margin-top: 22/16em;
        }

        h1 {
            font-size: 55/16rem;
            margin-top: 0;
            margin-bottom: 1rem;
        }
        @media @phone {
            margin-bottom: 32/16rem;
            h1 {
                font-size: 2rem;
            }
        }
    }

    &__hero {
        border-radius: 10/16rem;
        overflow: hidden;

        margin-bottom: 32/16rem;

        img {
            display: block;
            object-fit: cover;
        }

        @media @phone {
            margin-left: -1.5rem;
            margin-right: -1.5rem;
            border-radius: 0;

            margin-bottom: 48/16rem;
        }
    }

    &__author {

    }

    &__content {
        @media @phone {
            flex-direction: column;
        }

        .content-style {
            p:first-child {
                font-size: 20/18em;
                color: @colorHeader;

                @media @phone {
                    margin-top: 0;
                }
            }
        }
    }

}