.main-header {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  z-index: 12;
  color:#fff;
  transition: .3s ease;

  &__inner {
    width: 100%;
    max-width: 1238/16rem;
    margin:0 auto;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    width: 24.87562189%;
    a {
      display: block;
      max-width: 104/16rem;
    }

    svg {
      width: 100%;
      display: block;
      height: 34px;

      transition: .3s ease;
    }
  }

  &:not(.main-header--light, .has-active-submenu, .is-sticky) {
    .main-header__logo {
      svg {
        filter:  brightness(0) invert(1);
      }
    }
  }
  &:not(.main-header--light, .has-active-submenu, .is-sticky) {
    .main-header__logo {
      svg {
        filter:  brightness(0) invert(1);
      }
    }
  }

  &.is-sticky {
    position: fixed;
    background: #fff;
    animation: .3s slide-down 1;
  }

  @media @menu {
    body.is-menu & {
      &:not(.main-header--light, .has-active-submenu) {
        .main-header__logo {
          svg {
            filter:  unset;
          }
        }
      }
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }

  &__menu {
    flex-grow: 1;
  }

  &__more {
    width: 33.11258278%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20/16em;
  }

  &__submenu {
    position: absolute;
    top:100%;
    left:0;
    width: 100%;
    background: #fff;
    padding-top: 2.5em;
    padding-bottom: 2.5em;

    @media @menuUp {
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04), 0 0 5px 0 rgba(0, 0, 0, 0.04);
      z-index: 2;
      display: none;

      &.is-active-submenu {
        display: block;
        animation: fade-in .3s 1;
      }
    }

  }

  &.has-active-submenu {
    background: #fff;
  }

  &.has-active-submenu,
  &--light,
  &.is-sticky {
    color: @colorSecondary;

    .btn--secondary {
      --color:@colorSecondary;
      --colorHover: #fff;
      --bg:#EAEAEB;
      --bgHover:@colorSecondary;
    }
  }

  &__mobile {
    display: none;
  }

  .socials-list {
    display: none;
  }
}

.lang-tool {
  display: inline-block;
  position: relative;

  &.is-open {
    .lang-tool__popup {
      display: flex;
    }

    .lang-tool__open__chevron {
      transform: rotate(180deg);
    }
  }

  &__open {
    display: flex;
    align-items: center;
    gap: 8/14em;
    text-decoration: none;
    color:inherit;
    font-family: @fontHeader;


    &:hover {
      color: @colorText;
    }

    svg {
      width: 24/14em;
      height: 24/14em;
      stroke:currentColor;
      transition: stroke .3s ease;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;

    .font-size(p3);
  }


  @media @menu {
    .lang-tool__open__chevron {
      transform: rotate(180deg);
    }
    &.is-open .lang-tool__open__chevron {
      transform: rotate(0deg);
    }
  }

  &__popup {
    position: absolute;
    top:100%;
    right:0;
    background: #42434e;
    color: rgba(255, 255, 255, 0.7);
    //background: #fff;
    //color:@colorText;
    display: none;
    z-index: 10;
    padding: .7em 1em;
    backdrop-filter: blur(2px);
    margin-top: .5em;
    border-radius: .75em;
    //border:1px solid #dcdcdc;
    box-shadow: 0 0.5em 0.5em -0.25em rgba(0,0,0,0.2);

    @media @menu {
      top: auto;
      bottom: 100%;
      margin-bottom: .5em;
    }
  }

  &__header {
    //color:#707375;
    padding: 0 0 .75em;
    margin-bottom: .5em;
    line-height: 1;
    min-width: 140px;
    font-weight: @regular;
    font-family: @fontHeader;
    border-bottom: 1px solid rgba(255,255,255,.1);
  }


  &__item, .lang-item {
    display: block;
    padding: .5em .5em;
    position: relative;


    a {
      text-decoration: none;
      display: block;

      &:hover {
        color:rgba(255, 255, 255, 0.9);
      }
      &.is-active {
        font-weight: @bold;
        color:#fff !important;
      }
    }

    strong, &.current-lang {
      color:#fff !important;
      font-weight: @bold;
    }
  }
}

.main-menu {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-size: 18/16em;
  font-family: @fontHeader;
  gap:1em;
  list-style: none;

  a {
    color: inherit;
    display: block;
    padding: 2.2em 1em;
    text-decoration: none;
    position: relative;


    @media @menuUp {

      .is-sticky & {
        padding-top: 1.25em;
        padding-bottom: 1.25em;
      }
      &::before {
        content: "";
        position: absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 3px;
        background: @colorPrimary;
        transform:rotateY(90deg);
        transition: transform .3s ease;
      }

      &.is-active {
        &::before {
          transform:rotateY(0deg);
        }
      }
    }

    &:hover {
      color: @colorPrimary;
    }
  }

  svg {
    display: none;
  }

}

.submenu {
  width: 100%;
  max-width: 1238/16rem;
  margin:0 auto;
  padding: 0 1rem;
  display: flex;
  gap: 2em;
  font-family: @fontHeader;
  list-style: none;
  line-height: 1.3125;


  @media @menuUp {
    &.submenu-centered {
      justify-content: center;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: .5em 0 0;
  }


  a {
    text-decoration: none;

    &:hover {
      color: @colorPrimary;
    }
  }

  svg {
    flex: 0 0 12/16em;
    width: 12/16em;
    height: 21/16em;
    fill: currentColor;
  }

  & > li {
    max-width: 215/16rem;
    width: 100%;

    & > a {
      @media @menuUp {
        font-size: 18/16rem;
        font-weight: @bold;
      }

      svg {
        display: none;
      }
    }

    & > ul {
      a {
        display: flex;
        gap: 1em;
      }

      li:not(:last-child) {
        margin-bottom: 5/16em;
      }
    }
  }
}

@media @menu {
  .main-header {
    overflow: hidden;

    &__inner {
      padding: 1em;

      &::before {
        content: "";
        position: absolute;
        left:0;
        width: 100%;
        height: 78/18rem;
        background: #fff;
        z-index: 2;
        display: none;
      }
    }
    &__wrapper {
      position: absolute;
      left:0;
      top:0;
      width: 100%;
      height: 100dvh;
      padding-top: 78/20rem;
      justify-content: unset;
      align-items: unset;
      flex-direction: column;
      gap: 1.25em;
      background-size: auto 100%;
      transform: translateX(100%);
      transition: transform .3s ease;
      background: #fff;
    }

    &__menu {
      flex-grow: unset;
      border-top: 1px solid #F4F4F5;
    }

    &__more {
      width: 100%;
      flex-direction: column;

      .btn {
        display: none;
      }
    }

    body.is-menu & {
      color: @colorSecondary;
      overflow: unset;

      &.has-active-submenu .main-header__inner {
        &::before {
          display: block;
        }
      }
      &__logo {
        z-index: 2;
      }
      &__wrapper {
        transform: translateY(0);
      }
      &.has-active-submenu {
        .main-header__wrapper {
          transform: translateX(-100%);
        }
      }

      .btn--secondary {
        --color:@colorSecondary;
        --colorHover: #fff;
        --bg:#EAEAEB;
        --bgHover:@colorSecondary;
      }
    }

    &__mobile {
      display: flex;
      gap: 1.5em;
      align-items: center;
      z-index: 2;
      position: relative;
    }

    &__submenu {
      top:0;
      width: 100%;
      height: 100dvh;
      background-size: auto 100%;
      transform: translateX(100%);
      transition: transform .3s ease;
      background: #fff;
      overflow: auto;
      padding-top: 0;
      padding-bottom: 0;

      &.is-active-submenu {
        transform: translateX(0%);
      }
    }

    .socials-list {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 1em;
      list-style: none;
      margin-top: 1.25em;

    }
  }

  .main-menu {
    flex-direction: column;
    gap: unset;

    li {
      border-bottom: 1px solid #F4F4F5;
    }

    a {
      padding: .5em 1em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        flex: 0 0 12/16em;
        width: 12/16em;
        height: 21/16em;
        fill: currentColor;
        display: block;
      }
    }
  }


  .submenu {
    flex-direction: column;
    gap: 0;
    padding:78/18rem 0 0;
    font-size: 1.125em;
    transition: transform .3s ease;

    &.has-active-2level {
      transform: translateX(-100%);
    }

    li {
      max-width: unset;
      border-bottom: 1px solid #F4F4F5;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .5em 1em;
    }

    ul {
      margin: 0;
      padding: 0;
    }
    & > li {
      & > a {
        svg {
          display: block;
        }
      }

      & > ul {
        position: absolute;
        top:0;
        height: 100dvh;
        width: 100%;
        left:100%;
        padding-top: 78/18rem;
        display: none;
        overflow: auto;

        svg {
          display: none;
        }
      }

      &.is-active-2level {
        & > ul {
          display: block;
        }
      }
    }
  }
}