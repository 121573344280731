@font: "Open Sans", Helvetica, Arial, sans-serif;
@fontHeader: oscine, sans-serif;

@colorPrimary: #F3A083;
@colorSecondary: #2C2E3A;
@colorHeader: @colorSecondary;
@colorLight: rgba(243, 160, 131, 0.12);
@colorText: #636570;
@colorText2: #8D8E95;
@colorGray40: #8e909a;
@colorGray: #F3E6E1;

@colorLinkHover: ~'inherit';


@light: 300;
@regular: 400;
@medium: 500;
@semiBold: 600;
@bold: 700;
@extraBold: 800;

@retina: ~'(-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)';
@desktop: ~'(min-width: 1200px)';
@wide: ~'only screen and (min-width: 1919px)';
@laptopUp: ~'only screen and (min-width: 1400px)';
@laptop: ~'only screen and (max-width: 1399px)';
@tabletUp: ~'only screen and (min-width : 992px)';
@tablet: ~'only screen and (max-width : 991px)';
@phoneUp: ~'(min-width: 576px)';
@phone: ~'(max-width : 575px)';
@xsPhone: ~'(max-width: 370px)';

@menu: ~'(max-width : 900px)';
@menuUp: ~'(min-width: 901px)';


:root {

  --fs-top: 4.25rem; //68
  --ln-top: 72/68;

  --fs-h1: 3.125rem; //50
  --lh-h1: 1.2;

  --fs-h2: 2.8125rem; //45
  --lh-h2: 58/45;

  --fs-h3: 2.5rem; //40
  --lh-h3: 1.3;

  --fs-h4: 2.25rem;  //36
  --lh-h4: 46/36;

  --fs-h5: 1.5rem;  //24
  --lh-h5: 32/24;

  --fs-h6: 1.375rem; //22
  --lh-h6: 29/22;

  --fs-p1: 1.25rem; //20
  --lh-p1: 1.4;

  --fs-p: 1.125rem; //18
  --lh-p: 1.4;

  --fs-p2: 1rem; // 16
  --lh-p2: 1.375;

  --fs-p3: 0.875rem; // 14
  --lh-p3: 1.2666666667;

  --fs-p4: .5rem;
  --lh-p4: 1.4;

  --top-gap: 7rem;
  --section-gap: 5.75rem;


  @media @phone {
    --fs-h1: 2.25rem;
    --ln-height: 1.2;

    --fs-h2: 1.875rem;
    --lh-h2: 1.28;

    --fs-h3: 1.875rem;
    --lh-h3: 1.28;

    --fs-h4: 1.3334rem;
    --lh-h4: 1.28;

    --fs-h5: 1.25rem;
    --lh-h5: 1.28;

    --fs-p1: 1.125rem;
    --lh-p1: 1.4;


    --top-gap: 3rem;
  }
}