@import "_functions";
@import "_normalize";
@import "_variables";
@import "_base";
@import "_hamburger";
@import "_components";
@import "_splide";
@import "_plyr";
@import "_cookies";
@import "_form";
@import "_header";
@import "_footer";

@import "_glightbox";

[id] {
  scroll-margin-top: 82px;
}


.hero-section {
  background: @colorSecondary;
  min-height: 100svh;
  max-height: 900px;
  position: relative;

  @media @phone {
    min-height: 100svh;
  }

  &__bg {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: unset;
      transform-origin: 50% 100%;
    }

    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: unset;
      transform-origin: 50% 50%;
    }
  }
}


.hero-slider {
  z-index: 2;
  position: relative;

  &,
  .splide__track,
  .splide__list {
    min-height: inherit;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 120/16rem;
    padding-bottom: 140/16rem;
    color: #fff;

    h2 {
      color: inherit;
      .font-size(h1);
      margin-bottom: 1rem;
    }
    p {
      .font-size(p1);
    }


    h2, p {
      max-width: 500/16rem;
    }

    h2, p, .cta {
      opacity: 0;
      transform: translateX(3em);
      transition: opacity .3s ease, transform .3s ease;
    }

    &.is-active {
      h2, p, .cta {
        opacity: 1;
        transform: translateX(0);
        transition-duration: .3s, .3s;
      }

      h2 {
        transition-delay: .1s;
      }
      p {
        transition-delay: .15s;
      }
      .cta {
        transition-delay: .2s;
      }
    }
  }

  &__pagination {
    position: absolute;

    left:0;
    width: 100%;
    bottom:0;
    padding-bottom: 1em;

    .splide__pagination {
      position: relative;
      left: auto;
      bottom: auto;
      .section-inner();
      justify-content: flex-start;
      align-items: unset;
      gap: 2rem;

      li {
        max-width: 250/16rem;
      }
    }
    &__page {
      border:0;
      background: transparent;
      text-align: left;
      border-top:1px solid rgba(217, 217, 217, 0.5);
      color:rgba(255,255,255,.5);
      .font-size(p3);
      padding: 2em 1em 2em 0;
      transition: .3s ease;
      cursor: pointer;

      &:hover {
        border-top:1px solid rgba(217, 217, 217, 1);
        color:#fff;
      }

      &.is-active {
        color:@colorPrimary;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top:-1px;
          left:0;
          width: var(--progress);
          height: 1px;
          background:rgba(217, 217, 217, 1);
        }
      }
    }
  }

  @media @phone {
    min-height: 90svh;

    &__item {
      padding-bottom: 35%;
      padding-top: 4em;
      justify-content: flex-end;
    }
    &__pagination {

      .splide__pagination {
        gap: .5em;
      }

      span {
        display: none;
      }

      &__page {
        padding: .5em;
        border: 0 !important;

        &::before {
          content: "";
          display: block;
          width: 2em;
          height: 4px;
          background: rgba(255, 255, 255, 0.4);
          border-radius: 2px;
        }


        &.is-active {
          &::after {
            top:.5em;
            height: 4px;
            left:.5em;
            border-radius: 2px;
            background: @colorPrimary;
            width: ~'calc(var(--progress) * 0.7)';
          }

        }
      }
    }
  }
}

.about-home {
  @media @phoneUp {
    display: flex;
    gap: 3rem;
    justify-content: space-between;

    &__cta-mobile {
      display: none;
    }
  }

  &--page {
    max-width: 996/16rem;
    margin-left: auto;
    margin-right: auto;

    @media @phoneUp {
      .about-home {
        &__numbers {
          margin-top: 0;
          width: 25%;

        }
        &__content {
          width:70%;
          .font-size(p);
        }
      }
    }
  }

  @media @phoneUp {
    &__content {
      max-width: 860/16rem;
      width: 80%;
    }
  }

  &__numbers {
    display: flex;
    gap: 20/16rem;
    margin: 3em 0;

    @media @phoneUp {
      flex-direction: column;
      width: 22%;
      margin: 3.2rem 0 0;

      &__item {
        .font-size(p1);
      }
    }

    &__item {
      
      strong {
        display: block;
        font-weight: @bold;
        font-size: 60/20em;
        line-height: 1;

        color:transparent;
        position: relative;

        .number-animation {
          color: @colorPrimary;
        }
        &:not(.nb-counter) {
          color:@colorPrimary;
        }
      }
    }

    @media @phone {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__cta {
    margin-top: 40/16rem;

    @media @phone {
      display: none;
    }
  }
}

.quotation {
  background: @colorPrimary url(../img/bg-quotation.png) no-repeat 100% 100% / cover;
  color: #fff;
  text-align: center;
  .font-size(p1);
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding: 58/16rem 1.5rem;

  &--dark {
    background: @colorSecondary url(../img/bg-quotation-dark.png) no-repeat 0% 0% / cover;
  }

  h2 {
    color: inherit;
    margin-bottom: 0;
  }

  p {
    margin-top: 8/20em;
    margin-bottom: 34/20em;
  }


  @media @phoneUp {
    margin-left: 0;
    margin-right: 0;
    border-radius: 20/16rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.services {
  color: #fff;overflow: hidden;
  @media @phoneUp {
    .section-inner--my {
      margin-top: 124/16rem;
      margin-bottom: 184/16rem;
    }
  }

  @media @phone {
    margin-top: calc(-1* var(--top-gap)) !important;
  }

  &__header {
    margin-bottom: 2rem;

    @media @phoneUp {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 4rem;

      .section-title {
        margin-bottom: 0;
      }
    }
  }

  &__more {
    display: none;

    @media @phoneUp {
      display: block;
      margin-bottom: 1em;
    }
  }

  &__more-mobile {
    margin-top: 2em;

    @media @phoneUp {
      display: none;
    }
  }
}

.services-slider {
  color: @colorText;

  .splide__track {
    //overflow: unset;
    border-radius: 1em;
  }

  h4 {
    font-size: @colorSecondary;
  }
}

.service-item {
  background: #fff;
  box-shadow:inset 1px 0 0 0 #EAEAEB;
  padding: 66/16rem 48/16rem 54/16rem 54/16rem;
  display: flex;
  flex-direction: column;
  position: relative;

  .services-slider & {
    padding-left: 3em;
    padding-right: 3em;
    min-height: 23rem;
  }

  @media @phone {
    padding: 1.5rem;
    display: grid;
    gap: 1em;
    grid-template-columns: 3rem auto;
  }

  //&:first-child {
  //  border-radius: 1em 0 0 1em;
  //}
  //&:last-child {
  //  border-radius: 0 1em 1em 0;
  //}

  //&:not(:last-child) {
  //  border-right: 0;
  //}

  h4 {
    font-size: 1.5rem;
    margin:0;

    @media @phoneUp {
      margin: 0 0 .5em;
      font-size: 26/16rem;
      transition: color .3s ease, transform .3s ease;
      transform: translateY(94/16rem);
    }
  }

  &__ico {
    @media @phoneUp {
      position: absolute;
      opacity: 1;
      overflow: hidden;
      transform: translateY(0em);
      transition: opacity .15s ease, transform .3s ease;
    }

    img, svg {
      object-fit: contain;
      //width: 60/16rem;
      height: 60/16rem;
    }
  }

  &__content {
    .font-size(p3);

    .services-slider & {
      .font-size(p2);
      min-height: 0;
    }

    p:first-child {
      margin-top: 0;
    }

    grid-column: ~'1 / 3';
    @media @phoneUp {
      transition: transform .3s ease, opacity .3s ease;
      opacity: 0;
      transform: translateY(2em);
      min-height: 7em;
    }
  }

  &__footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: .5em;
  }

  &__more {
    margin-left: auto;
    color: @colorSecondary;
    display: flex;
    align-items: center;

    @media @phone {
      grid-column: ~'1 / 3';
    }

    a {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    svg {
      fill: currentColor;
    }
  }

  @media @phoneUp {
    overflow: hidden;

    &:hover {
      h4 {
        transform: translateY(0);
      }

      .service-item__ico {
        //transform: translateY(-2em);
        opacity: .2;
        transform: translate(10em, -3em) scale(1.2);;
        filter: grayscale(1);
        transition-delay: 0s, 0s;
      }
      .service-item__content {
        transition-delay: .15s, .2s;
        transform: translateY(0);
        opacity: 1;
      }

      h4 {
        color: @colorPrimary;
      }

      .service-item__more {
        color: @colorPrimary;
      }
    }
  }
}



.services-grid {
  margin-top: 68/16rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  //border-top: 1px solid #EAEAEB;
  //border-left: 1px solid #EAEAEB;

  @media @phone {
    grid-template-columns: 1fr;
  }

  .service-item {
    box-shadow: unset;
    border: 1px solid #EAEAEB;
    margin-top: -1px;
    border-radius: unset;

    @media @phoneUp {
      margin-left: -1px;
      &:first-child {
        border-top-left-radius: 8px; /* zaokrąglony lewy górny róg pierwszego elementu */
      }

      &:nth-child(4) {
        border-top-right-radius: 8px; /* zaokrąglony prawy górny róg czwartego elementu */
      }

      &:nth-last-child(-n+4):nth-child(4n+1) {
        border-bottom-left-radius: 8px; /* zaokrąglony lewy dolny róg pierwszego elementu w ostatnim wierszu */
      }

      &:nth-last-child(1) {
        border-bottom-right-radius: 8px; /* zaokrąglony prawy dolny róg ostatniego elementu w siatce */
      }

      &:nth-last-child(-n+4):nth-child(4n) {
        border-bottom-right-radius: 8px; /* zaokrąglony prawy dolny róg czwartego elementu w ostatnim wierszu, jeśli jest mniej niż 4 elementy w ostatnim wierszu */
      }
    }
    @media @phone {
      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

  }
}

.offer {
  border-radius: 20/16rem;
  border: 1px solid #EAEAEB;
  background: #fff;

  @media @phone {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  &__item {
    border-bottom: 1px solid #EAEAEB;
    padding: 32/16rem 6.1%;

    &:last-child {
      border-bottom: 0;
    }

    &__title {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      text-decoration: none;
      gap: 1.5em;

      &:hover {
        .offer__item__open {
          background: @colorPrimary;
          color: #fff;
        }
      }

      h3, h4, h5 {
        margin: 0;
        align-self: center;
      }
    }

    &__open {
      flex: 0 0 38/32em;
      width: 38/32em;
      height: 38/32em;
      border: 1px solid @colorPrimary;
      border-radius: 3em;
      color: @colorPrimary;
      font-weight: @light;
      font-size: 32/16rem;
      line-height: 1;
      transition: .3s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: currentColor;
        width: .5em;
        height: .5em;
        transform: rotate(0deg);
        transition: .3s ease;
      }

      .offer__item__open__minus {
        display: none;
      }
    }

    &__content {
      max-width: 770/16rem;
      max-height: 0;
      overflow: hidden;
      transition: max-height .3s ease;

      p {
        margin: 1em 0 1.5em;
      }
    }

    &.is-active {
      .offer__item__content:not([style]) {
        max-height: 40em;
      }
      .offer__item__open svg {
        transform: rotate(180deg);
      }
      .offer__item__open__minus {
        display: block;
      }
      .offer__item__open__plus {
        display: none;
      }
    }
  }
}

.clients {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin-bottom: 0;
  }
  a {
    display: block;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  &__title {
    white-space: nowrap;
    padding-right: 1em;
  }

  .clients-slider {
    @media @phone {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    @media @phoneUp {

      &.shadows {
        //border-left:1px solid #EAEAEB;
        //border-right:1px solid #EAEAEB;

        &::before {
          content: "";
          position: absolute;
          width: 8em;
          z-index: 1;
          left:0;
          top:0;
          bottom:0;
          background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
          pointer-events: none;
        }
        &::after {
          content: "";
          position: absolute;
          width: 8em;
          z-index: 1;
          right:0;
          top:0;
          bottom:0;
          background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
          pointer-events: none;
        }

        &::before {
          //content: "";
          //position: absolute;
          //inset: 0;
          //z-index: 5;
          //background:
          //        linear-gradient(to right, transparent 30%, rgba(255,255,255,0)),
          //        linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,.0) 70%) 0 100%,
          //        radial-gradient(farthest-side at 0% 50%, rgba(243, 23, 23, 0.6), rgba(255,255,255,0)),
          //        radial-gradient(farthest-side at 100% 50%, rgba(243, 25, 25, 0.6), rgba(255,255,255,0)) 0 100%;
          //background-repeat: no-repeat;
          //background-size: 4em 100%, 4em 100%, 1.4em 100%, 1.4em 100%;
          //background-position: 0 0, 100%, 0 0, 100%;
          //background-attachment: local, local, scroll, scroll;
          //pointer-events: none;
          //opacity: .5;
        }
      }
    }

  }

  @media @phone {
    flex-wrap: wrap;

    &__title {
      width: 100%;
      text-align: center;
    }

    &__item {
      max-width: 45%;
    }

    h3 {
      font-size: 1.6em;
    }
  }
}

.faq {
  &__item {
    border-bottom: 1px solid #EAEAEB;
    padding: 24/16rem 0;

    &__title {
      display: flex;
      gap: 1em;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        .faq__item__open {
          background: @colorPrimary;
          color: #fff;
        }
      }

      h5 {
        margin: 0;
        align-self: center;
        font-weight: @regular;
        font-family: @font;
        font-size: 1rem;

        @media @phoneUp {
          .font-size(p1);
        }
      }
    }

    &__open {
      flex: 0 0 38/32em;
      width: 38/32em;
      height: 38/32em;
      border: 1px solid @colorPrimary;
      border-radius: 3em;
      color: @colorPrimary;
      font-weight: @light;
      font-size: 32/16rem;
      line-height: 1;
      transition: .3s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: currentColor;
        width: .5em;
        height: .5em;
        transform: rotate(0deg);
        transition: .3s ease;
      }

      .faq__item__open__minus {
        display: none;
      }
    }

    &__content {
      max-width: 770/16rem;
      max-height: 0;
      overflow: hidden;
      transition: max-height .3s ease;
      .font-size(p3);

      p {
        margin: 1em 0 1.5em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.is-active {
      .faq__item__content:not([style]) {
        max-height: 10em;
      }
      .faq__item__open svg {
        transform: rotate(180deg);
      }
      .faq__item__open__minus {
        display: block;
      }
      .faq__item__open__plus {
        display: none;
      }
    }
  }
}

.faq-grid {
  @media @phoneUp {
    display: flex;
    justify-content: space-between;
    gap:2em;

    .section-title {
      max-width: 456/16rem;
      flex-grow: 1;
    }
    .faq {
      max-width: 510/16rem;
      flex-grow: 1;
    }
  }
}

.blog-grid {
  display: grid;
  gap: 32/16rem;
  grid-template-columns: repeat(3, 1fr);

  @media @phone {
    gap:3rem;
    grid-template-columns: 1fr;
  }
}

.blog-item {
  position: relative;

  &__url {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
  }
  &__thb {
    border-radius: 10px;
    padding-bottom: 230/380*100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 32/20rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      transition: transform .3s ease,  filter .3s ease;
      //transform-origin: 50% 100%;
      filter: grayscale(100%);
    }
  }

  &:hover {
    .blog-item__thb img {
      filter: grayscale(0%);
      transform: scale(1.1);
    }
    h2 {
      color: @colorPrimary;
    }

    .blog-item__more {
      color: @colorPrimary;
      transform: translateX(.5em);
    }
  }

  p, h2, .blog-item__more {
    transition: all .3s ease;
  }

  &__cat {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap:8px;
    font-size: 12/16rem;
    line-height: 1;
    font-weight: @medium;
    text-transform: uppercase;
    color: #fff;

    a {
      background: @colorPrimary;
      border-radius: 3px;
      padding: .3em .5em .2em;
      text-decoration: none;
      position: relative;
      z-index: 2;
      display: block;

      &:hover {
        color: @colorPrimary;
        background: transparent;
      }
    }
  }

  &__more {
    color: @colorSecondary;

    svg {
      width: 20/16rem;
      height: 20/16rem;
      fill: currentColor;
      stroke: currentColor;
    }
  }


  h2 {
    .font-size(p1);
    margin-top: 14/20em;
    margin-bottom: .5em;
  }

  p {
    .font-size(p3);
  }
}

.blog-homepage-title {
  @media @phoneUp {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 4rem; //46

    .section-title {
      margin-bottom: 0;
    }
  }

  &__more {
    color: @colorPrimary;
    display: none;

    @media @phoneUp {
      display: block;
    }
  }
}
.blog-more-mobile {
  margin-top: 2em;

  @media @phoneUp {
    display: none;
  }
}

.categories {
  display: flex;
  gap: 12/16rem;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 56/16rem;;
  list-style: none;

  @media @phone {
    .font-size(p3);
  }

  a {
    text-transform: uppercase;
    border-radius: 3px;
    padding: .22em .5em .2em;
    text-decoration: none;
    position: relative;
    z-index: 2;
    background: #F4F4F5;
    color:@colorText;

    &:hover {
      background: @colorSecondary;
      color: #fff;
    }

    &.is-active {
      background: @colorPrimary;
      color: #fff;
    }
  }
}

.tag {
  text-transform: uppercase;
  border-radius: 3px;
  padding: .22em .5em .2em;
  text-decoration: none;
  z-index: 2;
  background: #F4F4F5;
  color:@colorText;

  &--sm {
    .font-size(p3);
    color: @colorText2;
  }
}

.categories-mobile {
  display: none;
}

.contact-page {
  max-width: 850/16rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80/16rem;
  position: relative;
  z-index: 2;

  @media @phone {
    margin-top: 3rem;
  }
}

.contact-options {
  list-style: none;
  margin: 0 0 84/16rem;
  padding: 0;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  line-height: 1.5;
  text-align: center;
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h3 {
    font-size: 22/16rem;
  }



  @media @phone {
    flex-wrap: wrap;
    margin-bottom: 3rem;

    h3 {
      font-size: 20/16rem;
    }

    li {
      flex: 1 1 0;
    }
  }


  &__ico {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1rem;
    width: 90/16rem;
    height: 90/16rem;
    background: rgba(243, 160, 131, 0.2);
    border-radius: 10em;

    img {
      display: block;
      max-width: 100%;
    }
  }
}

.map-container {
  margin-top: -150/16rem;
  height: 622/16rem;
  width: 100%;
  position: relative;

  & + .main-footer {
    margin-top: 0;
  }
}

#map {
  width: 100%;
  height: 100%;
}

.map-pin {
  border-radius: 50%;


  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    margin-top: -1px;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #2c2e3a;
  }

  svg {
    width: 90/16rem;
    height: 90/16rem;
    display: block;
  }
}

.full-gallery {
  margin: 86/16rem 0 60/16rem;
  overflow: hidden;

  &__slider {
    max-width: 994/16rem;
    margin: 0 auto;
  }

  &__item {
    border-radius: 10px;

    img {
      display: block;
    }
  }

  .splide__track {
    overflow: unset;
  }

  .splide__arrows {
    position: absolute;
    bottom: 20/16rem;
    right: 30/16rem;
    display: flex;
    gap: 18/16rem;;
  }

  .splide__arrow {
    position: relative;
    left: auto;
    right: auto;
    top: auto;

    background: #fff;
    box-shadow: unset;
    transform: unset;
    width: 40/16rem;
    height: 40/16rem;

    svg {
      fill: @colorSecondary;
    }

    &:hover {
      box-shadow:0 0 0 8px rgba(255, 255, 255, 0.4);
    }
  }
}

.services-page {
  display: flex;
  gap: 3rem;
  flex-direction: column-reverse;

  @media @phoneUp {
    flex-direction: row;
    justify-content: space-between;
    gap: 4em;
    max-width: 1100/16rem;

    &__menu {
      width: 30%;
      max-width: 19.25rem;
      position: relative;
    }
    &__content {
      max-width: 43rem;
      width: 70%;
    }
  }

  @media @phone {

  }
}

.services-menu {
  position: sticky;
  top:4.5em;
  background: #F4F4F5;
  border-radius: 10px;
  padding: 54/16rem 1rem 54/16rem 1.75rem;

  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12/16em;
  font-family: @fontHeader;
  color: @colorText2;

  a {
    color: inherit;
    text-decoration: none;
    display: block;

    &:hover {
      color: @colorSecondary;
    }
  }


  ul {
    display: none;
    margin: 10/16em 0 1em;
    padding: 0;
    list-style: none;
    flex-direction: column;
    gap: 6/16em;
  }

  & > li {
    &.is-active {
      padding-left: 32/16rem;
      background: url(../img/arrow-primary.svg) no-repeat 0 0;
      background-size: 14/16rem 22/16rem;
      color: @colorSecondary;

      & > a {
        font-size: 18/16em;
        font-weight: @bold;
      }

      ul {
        display: flex;

        .is-active {
          font-weight: @bold;
        }
      }
    }
  }

  &.single-content-table {
    & > li {
      padding-right: 32/16rem;
      transition: padding .3s ease;

      &.is-active {
        padding-right: 0;

        a {
          font-size: 1em;
          font-weight: inherit;
        }
      }
    }
  }

  @media @phone {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}



.faq-page {
  display: flex;
  flex-direction: column;

  @media @phoneUp {
    flex-direction: row;
    justify-content: space-between;
    gap: 4em;
  }

  &__categories {
    position: relative;
    .font-size(p3);

    @media @phoneUp {
      width: 30%;
      max-width: 19.25rem;
      font-size: 1rem;

      &__sticky {
        position: sticky;
        top: 4.5em;
      }
    }

    h4 {
      font-size: 26/16em;
      margin-bottom: 16/24em;

      @media @phone {
        display: none;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 14/16rem;
      padding: 0;
      margin: 0;
      list-style: none;
      line-height: 1.375;

      @media @phone {
        .font-size(p3);
        flex-direction: row;
        flex-wrap: wrap;
      }

      a {
        text-transform: uppercase;
        border-radius: 3px;
        padding: 2/16em 15/16em 0;
        text-decoration: none;
        position: relative;
        z-index: 2;
        background: #F4F4F5;
        color:@colorText;
        display: inline-block;

        &:hover {
          background: @colorSecondary;
          color: #fff;
        }

        &.is-active {
          background: @colorPrimary;
          color: #fff;
        }
      }
    }

  }

  &__content {
    margin-top: 2em;

    @media @phoneUp {
      margin-top: 0;
      max-width: 49.375rem;
      width: 70%;
    }

    h1 {
      font-size: 26/16em;
      margin-bottom: 16/24em;
    }
  }

}

.team {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4em 2em;


  &__desc {
    grid-column: ~'1 / 4';
    .font-size(p1);

    h2 {
      .font-size(h4);
    }

    &__inner {
      max-width: 790/16rem;
    }
  }


  &__item {
    line-height: 1.5;
    font-size: 18/16em;
    overflow: hidden;
    color: @colorText2;

    &--about {
      @media @phone {
        grid-column: ~'1 / 3';
      }
    }

    &__photo {
      aspect-ratio: ~'1 / 1';
      border-radius: 10px;
      margin-bottom: 1em;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h3 {
      .font-size(p1);
      color: @colorText;
      margin: 0;
    }

    &__in {
      margin-top: 1em;

      a {
        display: inline-block;
        color: inherit;

        &:hover {
          color: @colorPrimary;
        }
      }

      svg {
        display: block;
        width: 20/16rem;
        height: 20/16rem;
        fill: currentColor;
      }
    }

    &__about {
      background: @colorPrimary url(../img/bg-team-item.png) no-repeat 50% 0% / cover;
      color:#fff;
      border-radius: 10px;
      aspect-ratio: ~'1 / 1';
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1.5em;

      h4 {
        font-size: 35/16rem;
        line-height: 40/35;
        margin: 0;
        color: inherit;
      }
      p {
        max-width: 240/20em;
        font-size: 20/16rem;
      }
    }
  }


  @media @phone {
    gap: 2em 1em;
    grid-template-columns: repeat(2, 1fr);

    &__desc {

      grid-column: ~'1 / 3';
      .font-size(p2);
    }

    &__item {
      font-size: 1rem;

      &__in {
        margin-top: .5em;
        svg {
          width: 1em;
          height: 1em;
        }
      }

      &__about {
        h4 {
          font-size: 1.5rem;
        }
        p {
          font-size: 1rem;
          max-width: 17em;
        }
      }
    }
  }
}

.about-hero {
  overflow: hidden;
  border-radius: 10px;
  margin-top: 60/16rem;
  margin-bottom: 80/16rem;

  img {
    display: block;
    width: 100%;
  }

  @media @phone {
    margin: 2em -1.5rem;
    border-radius: 0;
  }
}

.about-quote {
  background: @colorPrimary;
  color: #fff;
  text-align: center;
  padding-top: 120/16rem;
  padding-bottom: 120/16rem;

  blockquote {
    margin: 0;
    padding: 0;
    p {
      font-size: 32/16rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  cite {
    font-style: normal;
    font-size: 22/16rem;
  }


  @media @phone {
    padding-top: 3em;
    padding-bottom: 3em;

    blockquote {
      p {
        font-size: 1.25em;
      }

      cite {
        font-size: 1rem;
      }
    }
  }
}

.about-grid {
  overflow: hidden;

  &__row {
    display: flex;
    flex-direction: column-reverse;

    @media @phone {
      &:not(:last-child) {
        margin-bottom: 3em;
      }
    }

    @media @phoneUp {
      align-items: center;
      flex-direction: row;
      max-width: 996/16rem;
      margin: var(--top-gap) auto;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(odd){
        flex-direction: row-reverse;

        .about-grid__img__sizer {
          margin-left: -80%;
        }
      }

      &:nth-child(even) {
        .about-grid__img__sizer {
          margin-left: 32%;
        }
      }
    }
  }

  &__text {
    margin-top: 2em;

    @media @phoneUp {
      flex: 0 0 700/16rem;
      max-width: 700/16rem;
      margin-top: 0;

      //.font-size(p1);
    }

    .btn {
      margin-top: 1em;
    }
  }

  &__img {
    img {
      display: block;
      border-radius: 10px;
    }
    @media @phoneUp {
      align-self: flex-start;

      &__sizer {
        width: 145%;
      }
    }
  }
}

.why {

  margin: var(--top-gap) auto;
  max-width: 1028/16rem;
  color: #fff;


  h2 {
    color: inherit;
  }

  @media @phoneUp {
    display: flex;
    gap: 48/16rem;

    &__title {
      flex: 0 0 256/16rem;

      h2 {
        font-size: 40/16rem;
      }
    }
  }


  &__content {
    flex-grow: 1;
    display: grid;


    @media @phoneUp {
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;
    }
  }

  &__item {
    h4 {
      color: inherit;
      font-size: 24/16rem;
    }

    p {
      max-width: 310/16em;
    }

    &__ico {
      width: 88/16rem;
      height: 88/16rem;
      display: flex;
      align-items: center;

      svg {
        display: block;
      }
    }

    @media @phone {
      margin-bottom: 2em;

      h4 {
        margin-top: 0;
      }
      &__ico {
        float:left;
        margin-right: 1em;
      }
    }

  }

}

.testimonials {
  overflow: hidden;
  position: relative;
  z-index: 3;

  .splide__track {
    overflow: unset;
  }


  &__item {
    background: #F4F4F5 no-repeat url(../img/quote.svg) 93% 90%;
    border-radius: 20px;
    .font-size(p1);
    padding: 5%;
    display: flex;
    align-items: center;

    blockquote {
      display: block;
      margin: 0;
      padding: 0;

      p:first-child {
        margin-top: 0;
      }

      cite {
        display: block;
        font-style: normal;

        strong {
          display: block;
        }
      }
    }
  }
}

.page-error {
  text-align: center;
  padding-top: 12vh;
  padding-bottom: 8vh;

  strong {
    font-size: 5em;
    font-family: @fontHeader;
    line-height: .8;
  }
  p {
    font-size: 1.5em;
    margin: .5em 0 1em;
  }
  @media @phoneUp {
    font-size: 2em;
    padding-top: 10vh;
    padding-bottom: 5vh;
  }
}

@import "_wp";