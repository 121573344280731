.main-footer {
  width: 100%;
  margin: var(--section-gap) auto 0;
  .font-size(p3);

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .section-inner {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__bg {
    background: @colorSecondary;
    color: #fff;
    padding-top: 100/16rem;
    padding-bottom: 100/16rem;;

    .section-inner {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      gap: 2em;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    font-size: 12/16rem;
    padding-bottom: 54/16rem;
    padding-top: 54/16rem;
  }

  &__terms {
    list-style: none;
    display: flex;
    gap: 18/12em;
    margin: 0;
    padding: 0;
  }

  &__address {
    @media @phoneUp {
      max-width: 10rem;
      width: 20%;
    }

    .logo {
      width: 104/16rem;
      height: 34/16rem;
      filter: brightness(0) invert(1);
    }
  }

  &__contact {
    @media @phoneUp {
      max-width: 506/16rem;
      width: 45%;
    }

    h4 {
      font-size: 26/16rem;
      color:#fff;
    }
  }

  &__menu {
    margin-top: 2em;
    margin-bottom: 2em;

    @media @phoneUp {
      margin-top: 0;
      margin-bottom: 0;
      width: 35%;
      max-width: 260/16rem;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      font-family: @fontHeader;
      font-size: 18/16rem;
      font-weight: @bold;

      @media @phone {
        column-count: 2;
      }

      li {
        margin-bottom: 6px;
      }

      ul {
        margin: 5/14em 0 30/14em;
        font-family: @font;
        font-size: 14/16rem;
        font-weight: @regular;

        @media @phone {
          display: none;
        }
      }
    }
  }

  @media @phone {
    &__bg {
      padding-top: 2em;
      padding-bottom: 2em;
    }

    .section-inner {
      flex-direction: column;
    }

    &__bottom {
      gap: 2em;
    }
  }

}

.fixed-socials {
  color: #fff;

  @media (min-width: 1350px) {
    color: @colorText;
    position: fixed;
    right:.3em;
    padding: .5em;
    border-radius: .2em;
    top:50%;
    z-index: 2;
    //color:rgba(255,255,2555,.8);
    transform: translateY(-50%);
    //mix-blend-mode: difference;
    //mix-blend-mode: exclusion;
    mix-blend-mode: luminosity;
  }

  @media (min-width: 1440px) {
    right: ~'calc((100vw - 1440px)/2.5)';
  }
}

.socials-list {
  list-style: none;
  padding: 0;
  margin:2em 0 0;
  display: flex;
  gap: .75em;

  @media (min-width: 1350px) {
    flex-direction: column;
    gap: 2em;
  }

  svg {
    display: block;
    fill: currentColor;
    width: 20/16rem;
    height: 20/16rem;
  }

  a {
    display: block;
    padding: .25em;
    border-radius: 2em;

    &:hover {
      color:@colorPrimary;
    }
  }
}