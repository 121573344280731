.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  //float: left;
  font-family: inherit;
  font-weight: normal;

  height: 3.5em;
  font-size:1rem;
  line-height: 1;
  outline: none;
  padding: 1.25rem 2rem 1.25rem 0;
  position: relative;
  text-align: left !important;
  transition: all .2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  box-shadow: 0 8px 0 10px rgba(77, 78, 89, 0);
  color:#fff;

  label + select + & {
    padding-top: 1.75rem;
    padding-bottom: 0.75rem;
  }
}

.nice-select:hover {
  border-color: #fff
}
.nice-select.open {
  background:#4D4E59;
  box-shadow: 0 8px 0 10px #4D4E59;

  &::before {
    content: "";
    position: absolute;
    left:0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid #fff;
  }
}

.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #fff;
}

.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: "";
  display: block;
  height: .75rem;
  margin-top: -0.5rem;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all .15s ease-in-out;
  width: .75rem
}

.nice-select.open:after {
  transform: rotate(-135deg)
}

.nice-select.open .nice-select-dropdown {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0)
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none
}

.nice-select.disabled:after {
  border-color: #ccc
}

.nice-select.wide {
  width: 100%
}

.nice-select.wide .nice-select-dropdown {
  left: 0 !important;
  right: 0 !important
}

.nice-select.right {
  float: right
}

.nice-select.right .nice-select-dropdown {
  left: auto;
  right: 0
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px
}

.nice-select.small:after {
  height: 4px;
  width: 4px
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px
}

.nice-select .nice-select-dropdown {
  margin-top: 0;
  background-color: #4D4E59;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, .11);
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: -10px;
  right: -10px;
  transform-origin: 50% 0;
  transform: translateY(-19px);
  transition: all .2s cubic-bezier(0.5, 0, 0, 1.25), opacity .15s ease-out;
  z-index: 9;
  opacity: 0
}

.nice-select .list {
  //border-radius: 5px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 4px;
  max-height: 210px;
  overflow-y: auto;
  margin:0;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: rgba(0, 0, 0, 0) !important
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  outline: none;
  padding-left: 6px;
  padding-right: 2rem;
  text-align: left;
  transition: all .2s
}

.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: rgba(255,255,255,.1);
}

.nice-select .option.selected {
  font-weight: bold
}

.nice-select .option.disabled,
.nice-select .option[data-value='']{
  background-color: rgba(0, 0, 0, 0);
  color: #999;
  cursor: default
}


.nice-select .optgroup {
  font-weight: bold
}

.no-csspointerevents .nice-select .nice-select-dropdown {
  display: none
}

.no-csspointerevents .nice-select.open .nice-select-dropdown {
  display: block
}

.nice-select .list::-webkit-scrollbar {
  width: 0
}

.nice-select .has-multiple {
  white-space: inherit;
  height: auto;
  padding: 7px 12px;
  min-height: 36px;
  line-height: 22px
}

.nice-select .has-multiple span.current {
  border: 1px solid #ccc;
  background: #eee;
  padding: 0 10px;
  border-radius: 3px;
  display: inline-block;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 3px;
  margin-right: 3px
}

.nice-select .has-multiple .multiple-options {
  display: block;
  line-height: 24px;
  padding: 0
}

.nice-select .nice-select-search-box {
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  pointer-events: none;
  border-radius: 5px 5px 0 0
}

.nice-select .nice-select-search {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  color: #444;
  display: inline-block;
  vertical-align: middle;
  padding: 7px 12px;
  margin: 0 10px 0 0;
  width: 100%;
  min-height: 36px;
  line-height: 22px;
  height: auto;
  outline: 0 !important;
  font-size: 14px
}
