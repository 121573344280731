.input,
.group-control {
  .error-message {
    font-size:12px;
    color:#ff8d8d;
    margin:5px 0 0;
  }
}

.input-grid {
  display: flex;
  margin: 0.5rem 0;
  gap: 1.5em;

  & > .input {
    margin: 0;
    flex: 1 1 0px;
  }
}

.input {
  margin:8/16rem 0;


  &--select {
    position: relative;

    label {
      position: absolute;
      top: .25rem;
      left:0;
      width: 100%;
      font-size:12/16rem;
      text-align: left;
      color: rgba(255,255,255,.6);
      .transition();
      pointer-events: none;
      z-index: 2;
    }

    select {
      position: absolute;
    }
  }
  &--text {
    position: relative;

    &.is-error {

      select {
        position: absolute;
      }

      input,
      textarea,
      select {
        border-color:#ff8d8d;

        &:focus {
          border-color:#ff8d8d;
        }

      }

      input,
      textarea,
      select {
        padding-top: 1.75rem;
        padding-bottom: .75rem;
      }
    }

    .error-message {
      display: block;
      font-size:.75rem;
      text-align: left;
      opacity: 1;
      pointer-events: none;
    }

    label {
      position: absolute;
      top: .25rem;
      left:0;
      width: 100%;
      font-size:12/16rem;
      text-align: left;
      opacity: 0;
      color: rgba(255,255,255,.6);
      .transition();
      pointer-events: none;


      @media @phone {
        top:.25rem;
      }

      &.show {
        opacity: 1;
        z-index: 1;

        & + input,
        & + span input,
        & + span textarea {
          padding-top: 1.75rem;
          padding-bottom: .75rem;

          @media @phone {
            padding-top: 1em;
            padding-bottom: .5em;
          }
        }
      }
    }

    input,
    textarea {
      padding:1.25rem 0;
      width: 100%;
      background-color: transparent;
      border: 0;
      border-bottom:1px solid rgba(255,255,255,.5);
      border-radius: 0;
      height: 3.5em;
      font-size:1rem;
      line-height: 1;
      display: block;
      color:#fff;
      .transition();

      .placeholder({
        color: rgba(255,255,255,.6);
      });

      @media @phone {
        height: auto;
        padding: .75em 0;
      }

      &:hover {
        border-color: #fff;
      }

      &:focus {
        outline: 0;
        border-color:@colorPrimary;
      }

      &:is(:-webkit-autofill, :autofill) {
        background-color: transparent;
        border: 0;
        border-bottom:1px solid rgba(255,255,255,.5);
        color:#fff;
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;

      line-height: 1.375em;
      height: 5.75em;
      padding-top: 1.75rem;
    }

  }

  &--textarea {
    padding-top:1.625em;

    textarea {
      padding-top: 0 !important;
    }
  }

  &--submit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    .font-size(p3);
  }


  &--checkbox {
    .font-size(p3);
    position: relative;
    margin: 12/16rem 0;

    .input--text + & {
      margin-top: 24/16rem;
    }

    @media @phone {
      font-size:.8rem;
    }

    .error-message, .wpcf7-not-valid-tip {
      margin-top: .25em;
      padding-left: 2.125rem;

      @media @phone {
        font-size: .8rem;
        padding-left: 44/18em;
      }
    }

    &:hover {
      .input--checkbox__label::before,
      .wpcf7-list-item-label::before {
        border-color:#fff;
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      display: none;
      visibility: hidden;
      opacity: 0;

      &:checked ~ .input--checkbox__label,
      &:checked ~ .wpcf7-list-item-label {
        //&::before {
        //  border-color: @colorPrimary;
        //}
        &::after {
          transform: translate(-50%, -50%) scale(1) rotate(-45deg);
        }
      }
    }

    label {
      display: block;
      position: relative;
      text-align: left;
      padding-left: 34/16rem;
      color: rgba(255,255,255,.5);
    }

    &__label,
    .wpcf7-list-item-label{
      display: inline-block;

      span.input--checkbox__label__show-more,
      span.input--checkbox__label_less {vertical-align: baseline !important;}

      a {
        color:inherit;
      }

      & + br {
        display: none;
      }

      &__show-more,
      &__less {
        display: inline;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }

      &.is-active .input--checkbox__label__show-more {
        display: none;
      }

      &__more {
        overflow: hidden;
        max-height: 0;
        .transition(@prop: max-height);
      }

      a {
        color: inherit;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      &::before {
        //&::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        border: 1px solid rgba(255,255,255,.5);
        background: transparent;
        border-radius: 4px;
        width: 20/16rem;
        height: 20/16rem;
        .transition(@time: .2s);
      }

      &::after {
        content: "";
        position: absolute;
        left: 10/16rem;
        top: 8/16rem;
        width: .8em;
        height: .5em;
        border-bottom:2px solid @colorPrimary;
        border-left:2px solid @colorPrimary;
        .transition(@time: .15s, @ease: ease);
        transform: translate(-50%, -50%) scale(0) rotate(-45deg);
      }
    }

    .error-message, .wpcf7-not-valid-tip {
      &::after {
        left:14px;
      }
    }
    &.is-error {
      .input--checkbox__label::before,
      .wpcf7-list-item-label::before{
        border-color: @colorPrimary;
      }
    }
  }

  &--radio {
    padding:3px 20% 3px 45px;
    font-size:.875em;

    &:not(:last-child) {
      margin-bottom: 1em;
    }

    @media (max-width: 440px) {
      padding-right: 0px;
    }

    span {
      &::before,
      &::after {
        border-radius: 50%;
      }

      &::before {
        width: 24px;
        height: 24px;
      }

      &::after {
        top:12px;
        left:12px;
        width:16px;
        height: 16px;

        background: @colorPrimary;
        border-radius: 3px;
        opacity: 0;
      }
    }
  }

  &--info {
    .font-size(p3);
  }
}

.wpcf7-form .input--checkbox {
  padding-left: 0;

  .wpcf7-list-item {
    margin-left: 0;
  }
  label {
    padding-left: 35px;
  }
}

.style-select {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right:.8em;
    top:50%;
    margin-top: -.45em;
    width: .64em;
    height: .64em;
    border-bottom: 2px solid rgba(255,255,255,.5);;
    border-right: 2px solid rgba(255,255,255,.5);;
    pointer-events: none;
    transform: rotate(45deg);
  }

  select {
    -webkit-appearance: none;
    outline: 0;
    cursor: pointer;
    width: 100%;
    padding: 0;
    background-color: transparent;
    border: 0;
    border-bottom:1px solid rgba(255,255,255,.5);
    border-radius: 0;
    height: 3.5em;
    font-size:1rem;
    line-height: 1;
    display: block;
    color:#fff;

    &:invalid {
      color: rgba(255,255,255,.6);
    }

    &:invalid > option[value=""][disabled] {
      color: rgba(255,255,255,.6);
    }

    &:invalid {
      color: rgba(255,255,255,.6);
    }

    &[disabled] {
      color: rgba(255,255,255,.6);
    }

    option {
      background: #4D4E59;
      color: #fff;
    }
  }
}

.grecaptcha-badge { visibility: hidden; }

.wpcf7 {
  overflow: hidden;
}

.input-switch {
  position: relative;
  width: 56/20rem;
  height: 32/20rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &__checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:checked {
      & + .input-switch__label {
        background: @colorPrimary;

        .input-switch__switch {
          transform: translateX(24/20rem);

          i {
            color: @colorSecondary;
          }
        }
      }
    }
  }

  &__label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 3em;
    background: rgba(255, 255, 255, 0.4);
    transition: all 0.25s ease-out 0s;

    &:hover {
      box-shadow: inset 0 0 0 0 @colorSecondary, 0 0 0 3px @colorSecondary;
    }
  }

  &__inner {
    display: block;
  }

  &__switch {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left:0;
    top:0;
    width: 28/20rem;
    height: 28/20rem;
    margin: 2/20rem;
    background: #fff;
    border-radius: 2em;
    transition: all 0.25s ease-out 0s;

    i {
      font-size: 16/20rem;
      color:#fff;
      transition: all 0.25s ease-out 0s;

      &::before {
        margin: 0;
      }
    }
  }
}

.contact-form {
  background: @colorSecondary;
  color:#fff;
  padding: 68/16rem 2rem;
  border-radius: 20/16rem;

  @media @phone {
    padding: 2.8rem 1.5rem;
  }

  h4 {
    color: inherit;
  }

  .js-form {
    max-width: 632/16rem;
    margin: 0 auto;
  }
}

.js-form {
  position: relative;

  .wpcf7-response-output {
    padding: 1em !important;
    font-size: 12px;
    color: #ff8d8d;
    border: 1px solid #ff8d8d !important;
    border-radius: 10px;
  }

  &.sent {
    .wpcf7-response-output {
      display: none !important;
    }
  }

  &.submitted {
    .wpcf7-response-output {
      display: none !important;
    }
    &::before {
      content: "";
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background: rgba(44, 46, 58, .8);
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 60px;
      margin-top: -0.5em;
      margin-left: -0.5em;
      width: 1em;
      height: 1em;
      background: url(../img/loader.png) no-repeat 50% 50% / contain;
      display: block;

      animation: spin-reverse 1.5s infinite linear;
      z-index: 3;
    }

    &.sent {
      &::after {
        display: none;
      }
      .popup-status {
        display: flex;
      }
      .popup-status__success {
        display: block;
      }
    }

    &.invalid,
    &.unaccepted,
    &.spam,
    &.aborted,
    &.failed {

      &::after {
        display: none;
      }
      .popup-status {
        display: flex;
      }
      .popup-status__error {
        display: block;
      }
    }
  }
}



.popup-status {
  display: none;
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: @colorSecondary;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  color:#fff;
  z-index: 2;

  h3, h2, h1 {
    color: inherit;
  }

  .close-popup-status {
    position: absolute;
    cursor: pointer;

    right:0;
    top:0;
    color:inherit;

    background: 0;
    outline: 0;
    border: 2px solid #fff;
    border-radius: 3em;
    padding: .25em;
    transition: .3s ease;


    svg {
      display: block;
      width: 2em;
      height: 2em;
      stroke:currentColor;
      fill: currentColor;
    }

    &:hover {
      border-color: @colorPrimary;
      color: @colorPrimary;
    }
  }

  p {
    margin: 1em auto;
    max-width: 42em;
    .font-size(p1);
  }

  h3 {
    margin:0.2em 0;
  }

  &__success {
    display:none;

    svg {
      width: 4.5rem;
      height: 4.5rem;
      display: block;
      margin:0 auto;
    }
  }

  &__error {
    display:none;

    svg {
      color: @colorPrimary;
      width: 4.5rem;
      height: 4.5rem;
      display: block;
      margin:0 auto;
    }
  }

}

.expand-form {
  margin-top: 1.5em;
  margin-bottom: 1em;

  padding: 1em 30/16em;
  background: #373844;
  border-radius: 10px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5em;

    button {
      width: 36/16rem;
      height: 36/16rem;
      background: @colorPrimary;
      color:#fff;
      border: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3em;
      transform: rotate(180deg);

      svg {
        width: 9/16em;
        height: 13/16em;
      }
    }
  }

  &__content {
    margin-top: 1.5em;
    display: none;
  }

  &.is-expanded {
    .expand-form__header button {
      transform: rotate(0deg);
    }
    .expand-form__content {
      display: block;
    }
  }
}

.wpcf7-form .wpcf7-response-output {
  display: none !important;
}

@import "_nice-select2";