.is-cookie-popup {
  overflow: hidden;
}

.cookies-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height: 100%;
  background: rgba(20,37,58,.4);
  z-index: 1020;
  backdrop-filter: blur(1px);
}

.cookies {
  //position: fixed;
  //bottom: 1rem;
  //left:1rem;
  max-width: 560px;
  width: 95%;
  background: #2a2d37;
  color:rgba(255,255,255,.8);
  overflow: hidden;
  font-size: 16px;


  @media @tablet {
    left:5%;
  }


  .sygnet {
    position: absolute;
    top:-50%;
    left:-3%;
    width: 300px;
    opacity: .7;
    pointer-events: none;
  }

  a:not([class])  {
    text-decoration: underline;
    color:#fff;

    &:hover {
      text-decoration: none;
    }
  }

  &__text {
    padding: 2em 2.6923076923em;
    max-height: 80vh;
    overflow: auto;
  }

  h3 {
    color:#fff;
    font-size:1.3em;
    margin:0;
  }

  p {
    margin:1.25em 0;
  }

  &__terms {
    color:#fff;

    a {
      margin:.5em 0;
    }
  }
  &__footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__hidden {
    visibility: hidden;
    position: absolute;
    height: 0;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    margin-top: 1em;

    .btn {
      padding: 1em;
      font-size: 0.8125em;
    }
    .btn--primary {
      background: transparent;
      color:@colorPrimary;
      &:hover {
        color:#fff;
        background-color: @colorPrimary;
        border-color:@colorPrimary;
      }
    }
  }

  &--extend {
    max-width: 560px;
    p {
      color: rgba(255, 255, 255, 0.8);
      margin: .8em 0;
    }
  }

  .cookies-always {
    font-size:14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: right;
    display: block;
    line-height: 16/14;
    position: relative;

    input {
      visibility: hidden;
      position: absolute;
      height: 0;
    }
  }

  &__title {
    margin-bottom: .75em;
  }

  &__list {
    width: 100%;
    margin-bottom: 1em;

    strong {
      color:#fff;
      font-size: 1.125em;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: .8em;
      border-top: 1px solid rgba(255,255,255,.2);

      &__desc {
        width: 100%;

        &:first-child {
          p {
            margin-top: 0;
          }
        }
      }
    }
  }

  @media @phone {
    border-radius: 8px;

    &__content {
      flex-direction: column;

      & > p {
        margin:0;
        padding-left: 32/18rem;
      }
      & > svg {
        position: absolute;
        left:.75rem;
        top:.75rem;
      }
    }

    &__close {
      flex-direction: column;
    }

    &__text {
      padding: 1.25em;
      max-height: 95vh;
    }

    &--extend {
      font-size: .9em;
      p {
        padding-left: 0;
      }

      .cookies__btns {
        flex-direction: column;
        margin-top: 0;

        .btn {
          margin: 4/18rem 0;
        }
      }
    }

    &__btns {
      margin-top: 12/18rem;
      width: 100%;
    }

    &__list {

      &__item {
        padding: .5em 0;

        & > div:nth-child(2) {
          margin-left: auto;
        }

        & > div:first-child {
          padding-right: 16/18rem;
        }
      }

      .p-size-2 {
        font-size: 14/18rem;
      }
    }

    .btn {
      flex-grow: 1;
    }
  }
}