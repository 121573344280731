*, *:before, *:after {
  box-sizing: inherit;
}


html {
  font-size: 16px;
  line-height: 1.375;
  font-weight: @regular;
  box-sizing: border-box;

  @media (max-width: 393px) {
    font-size: 4.266666667vw;
  }
}

body {
  text-rendering: optimizeSpeed;
  font-family: @font;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: @colorText;
  -webkit-font-smoothing: antialiased;
  background: #fff url(../img/bg-page.png) no-repeat 50% 0;
  background-size: 100%;

  &.is-popup {
    overflow: hidden;
  }
}

@media @menu {
  .hamburger {
    display: block;
  }

  body {
    overflow-x: hidden;

    &.is-menu,
    &.is-search {
      overflow: hidden;
    }
  }
}

a {
  transition: 0.3s ease;
  color: inherit;
}

strong {
  font-weight: @bold;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 0;
}