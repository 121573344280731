.transition (@time: 0.2s, @prop: all, @ease: ease, @delay: 0s) {
    transition+: @prop @time @ease @delay;
}

.translate (@x: 0, @y: 0) {
    transform: translate(@x, @y);
}

.bg-gradient (@start: #1166AE, @startPos: 0%, @stop: #2BACE1, @stopPos: 100%, @angle: 90deg) {
    background: @start;
    background: linear-gradient(@angle, @start @startPos, @stop @stopPos);
}

.placeholder(@rules) {
    &::-webkit-input-placeholder {
        @rules();
    }
    &:-moz-placeholder {
        @rules();
    }
    &::-moz-placeholder {
        @rules();
    }
    &:-ms-input-placeholder {
        @rules();
    }
    //&[placeholder] {
    //    @rules();
    //}
}

.clearfix {
    zoom: 1;
    &:before, &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

.icon-style() {
    font-family: "icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.font-size(@size) {
    font-size: ~'var(--fs-@{size})';
    line-height: ~'var(--lh-@{size})';
}

@keyframes spin-reverse {
    0% { transform: rotate(0deg)}
    100% {  transform: rotate(-360deg)}
}
@keyframes spin {
    0% { transform: rotate(0deg)}
    100% { transform: rotate(360deg)}
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slide-down {
  from {
    transform: translate3d(0, -100%, 0)
  }
  to {
    transform: translate3d(0, 0, 0)
  }
}

@keyframes open-popup {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}


@keyframes scroll-to {
    0%, 75% { transform: translateY(0)}
    100% { transform: translateY(15px)}
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(243, 160, 131, 0.40);
    }
    70% {
        box-shadow: 0 0 0 20px rgba(243, 160, 131, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(243, 160, 131, 0);
    }
}